import { Request } from '../_until';
import { notification } from 'antd';

export const vps = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeRenew,
    deleteItems,
    getListFrontend,
    buyVps,
    getDetailFrontend,
    reinstallFrontend,
    renewFrontend,
    changeIpFrontend,
    changeAutoRenewFrontend,
    autoFixFrontend,
    pauseActionFrontend,
    rebootFrontend,
    noteVpsFrontend,
    bucketActionFrontend,
    getContentChangeIpFrontend,
    checkPriceChangeIpFrontend,
    getListOsFrontend
};

const urls = {
    get_list: `/vps`,
    get_item: `/vps/get/`,
    add_item: `/vps/add`,
    edit_item: `/vps/update`,
    change_renew: `/vps/change_renew/`,
    delete_item: `/vps/delete`
};

const websiteUrls = {
    get_list: `vps`,
    buy_vps: `vps/buy_vps`,
    get_item: `vps/detail/`,
    reinstall: `vps/reinstall/`,
    renew: `vps/renew/`,
    change_ip: `vps/change_ip/`,
    change_auto_renew: `vps/change_auto_renew/`,
    auto_fix: `vps/auto_fix/`,
    pause_action: `vps/pause_action/`,
    reboot: `vps/reboot/`,
    note_vps: `vps/note_vps/`,
    bucket_action: `vps/bucket_action`,
    get_content_change_ip_vps: `vps/get_content_change_ip_vps/`,
    check_price_change_list_ip: `vps/check_price_change_list_ip`,
    get_list_os: `vps/list_os`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function changeRenew(id, requestOptions) {
    return await Request.post(urls.change_renew + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function getListFrontend(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(websiteUrls.get_list + '?' + queryString, {}).then(res => res.data);
};

async function getDetailFrontend(id) {
    return await Request.get_website(websiteUrls.get_item + id, {}).then(res => res.data);
};

async function buyVps(requestOptions) {
    return await Request.post_website(websiteUrls.buy_vps, requestOptions).then(res => res.data);
};

async function reinstallFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.reinstall + id, requestOptions).then(res => res.data);
};

async function renewFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.renew + id, requestOptions).then(res => res.data);
};

async function changeIpFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.change_ip + id, requestOptions).then(res => res.data);
};

async function changeAutoRenewFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.change_auto_renew + id, requestOptions).then(res => res.data);
};

async function autoFixFrontend(id) {
    return await Request.post_website(websiteUrls.auto_fix + id, {}).then(res => res.data);
};

async function pauseActionFrontend(id) {
    return await Request.post_website(websiteUrls.pause_action + id, {}).then(res => res.data);
};

async function rebootFrontend(id) {
    return await Request.post_website(websiteUrls.reboot + id, {}).then(res => res.data);
};

async function noteVpsFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.note_vps + id, requestOptions).then(res => res.data);
};

async function bucketActionFrontend(requestOptions) {
    return await Request.post_website(websiteUrls.bucket_action, requestOptions).then(res => res.data);
};

async function getContentChangeIpFrontend(id) {
    return await Request.post_website(websiteUrls.get_content_change_ip_vps + id, {}).then(res => res.data);
};

async function checkPriceChangeIpFrontend(listIds) {
    return await Request.post_website(websiteUrls.check_price_change_list_ip, {'ids': listIds}).then(res => res.data);
};

async function getListOsFrontend() {
    return await Request.post_website(websiteUrls.get_list_os, {}).then(res => res.data);
};