import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Spin, Radio, Form, Input, InputNumber, message, Button, notification, Checkbox, Select } from 'antd';
import { account, authenticator, plan, proxy } from '../../endpoint';
import { LockOutlined } from '@ant-design/icons';
import AppContext from './../../_until/AppContext';

const BuyProxyComponent = () => {
    const history  = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dataPackage, setDataPackage] = useState([]);
    const [dataRangeIps, setDataRangeIps] = useState(null);
    const [dataIsp, setDataIsp] = useState(null);
    const [dataState, setDataState] = useState(null);
    const [month, setMonth] = useState(1);
    const [qty, setQty] = useState(1);
    const [checkedRadomPassword, setCheckedRadomPassword] = useState(true);
    const [checkedAutoRenew, setCheckedAutoRenew] = useState(true);
    const [checkIhaveRead, setCheckIhaveRead] = useState(false);
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);
    const { id } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    if (id == null) {
        history.push({pathname: '/home'});
    }

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    /**
     * Load list package proxy
     *
     * @param e
     */
    const loadPackage = () => {
        plan.getItemPackageProxy(id).then(res => {
            if (res.status == 'success') {
                setDataPackage(res.response.package);
                setDataRangeIps(res.response.rangeIps);
                setDataIsp(res.response.isp);
                setDataState(res.response.state);
                document.title = res.response.package.plan_name + " - " + window?.title;
            }
            setLoading(false);
        }).then(res => {
        });
    }

    /**
     * Handle change month
     *
     * @param e
     */
    const onChangeMonth = (e) => {
        setMonth(e.target.value);
    };

    /**
     * Handle change randomd password
     *
     * @param e
     */
    const onChangeRandomPassword = (e) => {
        setCheckedRadomPassword(e.target.checked);
    };

    /**
     * Handle change auto renew
     *
     * @param e
     */
    const onChangeAutoRenew = (e) => {
        setCheckedAutoRenew(e.target.checked);
    };

    /**
     * Handle buy proxy
     *
     * @param fields
     */
    const onFinish = async (fields) => {
        if (!checkIhaveRead) {
            notification.open({
                message: 'Fail!',
                description: 'I have read and agree is required',
                type: 'error'
            });
            return;
        }
        const hide = message.loading('Processing...');
        setLoadingButton(true);
        try {
            var res = await proxy.buyProxy({...fields, 'auto_renew': checkedAutoRenew ? 1 : 0, 'id': id});
            console.log(res);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                setLoadingButton(false);
                setLoadNotification(true);
                history.push({pathname: '/list-proxy.html'});
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Buy failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Buy Proxy - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadPackage();
                }
            }).then(res => {
            });
        }
    }, []);
    
    return (
        <div className="container">
            {
                loading ? 
                (<div className="text-center mt-5"><Spin /></div>) : ''
            }
            <div className={(loading ? 'd-none' : '')}>
                <Form
                    name="basic"
                    onFinish={onFinish}
                >
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Validity Period </h4>
                                </div>
                                <div className='panel-body'>
                                    <div className="row row-eq-height">
                                        <Form.Item
                                            label="Validity Period:"
                                            labelCol={{span: 24}}
                                            name="month"
                                            rules={[{ required: true, message: 'Please choose Validity Period !'}]}
                                            initialValue={1}
                                        >
                                            <Radio.Group onChange={onChangeMonth} value={month}>
                                                <Radio value={1}>1 Month</Radio>
                                                <Radio value={3}>3 Months</Radio>
                                                <Radio value={6}>6 Months</Radio>
                                                <Radio value={12}>12 Months</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Quantity want to buy</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <Form.Item
                                                label="Quantity:"
                                                name="qty"
                                                labelCol={{span: 24}}
                                                rules={[{ required: true, message: 'Please enter qty!'}]}
                                                initialValue={qty}
                                            >
                                                <InputNumber min={1} max={50} placeholder="Qty" style={{width: '100%'}} onChange={(value) => {setQty(value)}} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Information</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <Form.Item 
                                                name="proxy_type"
                                                label="Proxy Type:"
                                                labelCol={{span: 24}}
                                                rules={[{ required: true, message: 'Please choose Proxy Type!' }]}
                                                initialValue="proxy_sock_5"
                                            >
                                                <Select
                                                    style={{width: "100%"}}
                                                    placeholder="Proxy Type"
                                                >
                                                    <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                                    <Option value="proxy_https">HTTPS Proxy</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            {
                                                dataState && dataState.length > 0 ? (
                                                    <Form.Item name="state" label="State:" labelCol={{span: 24}}>
                                                        <Select
                                                            style={{width: "100%"}}
                                                            placeholder="Random"
                                                            defaultValue="random"
                                                        >
                                                            <Option value="random">Random</Option>
                                                            {
                                                                dataState.map((item, key) => 
                                                                    <Option key={item} value={item}>{item}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                ) : ''
                                            }
                                            {
                                                dataIsp && dataIsp.length > 0 ? (
                                                    <Form.Item name="id_isp" label="Provider:" labelCol={{span: 24}}>
                                                        <Select
                                                            style={{width: "100%"}}
                                                            placeholder="Random"
                                                            defaultValue="random"
                                                        >
                                                            <Option value="random">Random</Option>
                                                            {
                                                                dataIsp.map((item, key) =>
                                                                    <Option key={item} value={item}>{item}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Form.Item name="random_password" labelCol={{span: 24}} style={{marginBottom: "5px"}}>
                                                <Checkbox checked={checkedRadomPassword} onChange={onChangeRandomPassword}>Random password</Checkbox>
                                            </Form.Item>
                                            {
                                                !checkedRadomPassword ? (
                                                    <div className="password-wrap mt-2">
                                                        <Form.Item
                                                            label="Password:"
                                                            labelCol={{span: 24}}
                                                            name="password"
                                                            rules={[
                                                                { required: true, message: 'Please input your password!' },
                                                                { minlength: 6, message: 'Password must have a minimum length of 6' },
                                                                { minlength: 30, message: 'Password must have a max length of 30' },
                                                                {
                                                                    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                                                    message: 'Password must contain at least 10 characters, 1 uppercase letter, 1 lowercase character, 1 special character and 1 number.'
                                                                }
                                                            ]}
                                                            style={{marginBottom: "5px"}}
                                                        >
                                                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} minLength={6} maxLength={30} placeholder="Password" />
                                                        </Form.Item>
                                                    </div>
                                                ) : ""
                                            }
                                            <div className="m-t-2" id="tos-checkbox">
                                                <Form.Item name="auto_renew" style={{marginBottom: "5px"}}>
                                                    <Checkbox checked={checkedAutoRenew} onChange={onChangeAutoRenew}>Auto-renew</Checkbox>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0 text-white">Information order</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="summary-content">
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Country</span>
                                                <span className="item-value">{dataPackage?.country}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Price 1 month</span>
                                                <span className="item-value">{dataPackage?.plan_price} Point</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list d-none">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Discount</span>
                                                <span className="item-value">0 Point</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item list-item-main d-flex justify-content-between align-items-center">
                                                <span className="item-name">Validity Period</span>
                                                <span className="item-value">{month} months</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item list-item-main d-flex justify-content-between align-items-center">
                                                <span className="item-name">Total</span>
                                                <span className="item-value">{dataPackage?.plan_price * month * qty} Point</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Form.Item
                                name="i_have_read"
                                valuePropName="checked"
                                rules={[
                                    { required: true, message: 'I have read and agree is required' }
                                ]}
                            >
                                <Checkbox checked={checkIhaveRead} onChange={(e) => {setCheckIhaveRead(e.target.checked);}}>I have read and agree to the <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target='_blank'>Terms of Service</a></Checkbox>
                            </Form.Item>
                            <Button type="primary" htmlType="submit" loading={loadingButton} className="medium d-block text-bold">
                                <i className="fa fa-share pr-1"></i> Payment Now
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}
export default BuyProxyComponent;
