import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Pagination, Spin, notification, message, Modal } from 'antd';
import { sock_list, account, banner, authenticator } from '../../endpoint';


const SockApi = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [dataCountry, setDataCountry] = useState([]);
    const [dataState, setDataState] = useState([]);
    const [dataCity, setDataCity] = useState([]);
    const [dataLists, setDataLists] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataAds, setBannerAds] = useState([]);
    const [first, setFirst] = useState(true);
    const [fillSock, setfillSock] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    const onFinish = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);
        sock_list.downloadSockAll(values).then(res => {
            setLoading(false);
            if (res.status == 'success') {
                const element = document.createElement("a");
                element.href = res.url;
                element.download = "listsock5.txt";
                document.body.appendChild(element);
                element.click();
                element.removeChild(link);
                setFirst(false);
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            console.log(err);
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    };

    const changeTypeSock = (values) => {
        sock_list.getCountry({all: 1, type: values}).then(res => {
            if (res.status == 'success') {
                setDataCountry(res.response);
            }
        });
    }

    const changeCountry = (values) => {
        sock_list.getState({all: 1, sock_country: values}).then(res => {
            if (res.status == 'success') {
                setDataState(res.response);
            }
        });
    }

    const changeState = (values) => {
        sock_list.getCity({all: 1, sock_country: dataCity, sock_states: values}).then(res => {
            if (res.status == 'success') {
                setDataCity(res.response);
            }
        });
    }

    const showFill = () => {
        setfillSock(true);
    }

    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    const getLevel = (level) => {
        if (level == 1) {
            return "Low";
        } else if (level == 2) {
            return "Medium";
        } else if (level == 3) {
            return "Hight";
        } else if (level == 4) {
            return "Very High";
        }
        return "";
    }

    useEffect(() => {
        document.title = "Socks5 All - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                        }
                    }).then(err => {
                        setLoadingProfile(false);
                    }).then(complete => {
                        setLoadingProfile(false);
                    });
                }
            }).then(res => {
            });
        }

        if(user != null) {
            sock_list.getCountry({all: 1}).then(res => {
                if (res.status == 'success') {
                    setDataCountry(res.response);
                }
            }).then(err => {
                setLoading(false);
            }).then(complete => {
                setLoading(false);
            });

            // sock_list.getState({all: 1}).then(res => {
            //     if (res.status == 'success') {
            //         setDataState(res.response);
            //     }
            // });

            // sock_list.getCity({all: 1}).then(res => {
            //     if (res.status == 'success') {
            //         setDataCity(res.response);
            //     }
            // });
        }

        banner.get_list_banner({position: 'sock'}).then(res => {
            if (res.status == 'success') {
                setListBanner(res.response);
            }
        });
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row align-items-center">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content search-socks-list">
                {
                    loadingProfile ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        profile?.plan_type != "API" ? (
                            <div className="alert alert-danger text-center" style={{maxWidth: "800px", margin: "15px auto"}}>Please buy plan Socks5 All before viewing this item</div>
                        ) : 
                        (
                            <div>
                                <div className="form-search">
                                    <div className="padding-10">
                                        <Form
                                            name="basic"
                                            className="form-horizontal mt-3"
                                            onFinish={onFinish}
                                        >
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h5>Search Socks</h5>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <Form.Item name="sock_country">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="All Country"
                                                                        onChange={changeCountry}
                                                                        filterOption={(input, option) =>
                                                                            (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase()) ||
                                                                            (input.length == 2 && option.props.value.toLowerCase() == input.toLowerCase())
                                                                        }
                                                                    >
                                                                        <Option value="all">All Country</Option>
                                                                        {
                                                                            dataCountry.length  > 0 && dataCountry.map((item, key) => 
                                                                                <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <Form.Item name="sock_state">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="All State"
                                                                        onChange={changeState}
                                                                        filterOption={(input, option) =>
                                                                            (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                        }
                                                                    >
                                                                        <Option value="all">All State</Option>
                                                                        {
                                                                            dataState.length  > 0 && dataState.map((item, key) => 
                                                                                <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <Form.Item name="sock_city">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="All City"
                                                                        filterOption={(input, option) =>
                                                                            (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                        }
                                                                    >
                                                                        <Option value="all">All City</Option>
                                                                        {
                                                                            dataCity.length  > 0 && dataCity.map((item, key) => 
                                                                                <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <Form.Item name="black_list">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="Black List"
                                                                    >
                                                                        <Option value="">All</Option>
                                                                        <Option value="0">Clear</Option>
                                                                        <Option value="1">BlackList</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <h5>Search Zipcode</h5>
                                                    <div className="row">
                                                        <div className="form-group col-sm-12">
                                                            <Form.Item name="sock_zipcode">
                                                                <Input style={{width: "100%"}} name="txtZip" placeholder="Search Zipcode"/>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10">
                                                    <h5>ISP Name</h5>
                                                    <div className="row">
                                                        <div className="form-group col-sm-2">
                                                            <div className="form-group">
                                                                <Form.Item name="sock_hostname">
                                                                    <Input style={{width: "100%"}} name="txtHostName" placeholder="Search ISP Name"/>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-group">
                                                                <Form.Item name="level">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="Rich Level"
                                                                    >
                                                                        <Option value="">All</Option>
                                                                        <Option value="1">Low</Option>
                                                                        <Option value="2">Medium</Option>
                                                                        <Option value="3">Hight</Option>
                                                                        <Option value="4">Very High</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-group">
                                                                <Form.Item name="type_ip">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="Type IP"
                                                                    >
                                                                        <Option value="">All</Option>
                                                                        <Option value="0">Real</Option>
                                                                        <Option value="1">Encryption</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-sm-2">
                                                            <div className="form-group">
                                                                <Form.Item name="sock_symbol">
                                                                    <Select
                                                                        style={{width: "100%"}}
                                                                        placeholder="Type Sock"
                                                                    >
                                                                        <Option value="all">All</Option>
                                                                        <Option value="dt">Datacenter</Option>
                                                                        <Option value="rs">Residential</Option>
                                                                        <Option value="mb">Mobile</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 text-right">
                                                            <div className="col-auto">
                                                                <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Download ALL Sock5</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Socks5 All</div>
                                    <div className="panel-body">
                                        <div className="table-responsive">
                                            {
                                                loading ? (
                                                    <div className="text-center mt-5 mb-5"><Spin /></div>
                                                ) :
                                                (
                                                    <table className="table table-striped table-sock-api">
                                                        <thead>
                                                            <tr>
                                                                <th>Sock5</th>
                                                                <th>Country</th>
                                                                <th>Region</th>
                                                                <th>City</th>
                                                                <th>Zipcode</th>
                                                                <th>ISP Name</th>
                                                                <th>Type Sock</th>
                                                                <th>Type IP</th>
                                                                <th>Mobile</th>
                                                                <th>Risk Score</th>
                                                                <th>Risk Level</th>
                                                                <th>Spam Mail</th>
                                                                <th>Online</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                dataLists.length  > 0 && dataLists.map((item, key) => 
                                                                    <tr key={item.sock_id}>
                                                                        <td className="sock">{item.sock_ip}</td>
                                                                        <td>
                                                                            <img src={item.country_img} />&nbsp;&nbsp;
                                                                            {item.sock_country}
                                                                        </td>
                                                                        <td>{item.sock_states}</td>
                                                                        <td>{item.sock_city}</td>
                                                                        <td>{item.sock_zip}</td>
                                                                        <td className="host">{item.sock_hostname}</td>
                                                                        <td>{item.sock_symbol == 'rs' ? 'Residential' : 'Datacenter'}</td>
                                                                        <td>{item.type_ip == '1' ? 'Encryption' : 'Real'}</td>
                                                                        <td>{item.is_mobile == '1' ? 'Yes' : 'No'}</td>
                                                                        <td>{item.score}/100</td>
                                                                        <td>{getLevel(item.level)}</td>
                                                                        <td>{item.sock_back_list == '1' ? 'BlackList' : 'No'}</td>
                                                                        <td>{item.sock_times}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            <tr className={dataLists.length == 0  && first ? '' : 'd-none'}>
                                                                <td colSpan="13" className="text-center">
                                                                    <b>Please click Download All Socks5 <br/> 
                                                                    After click Download All Socks5 please wait 2-3 min</b>
                                                                </td>
                                                            </tr>
                                                            <tr className={dataLists.length == 0  && !first ? '' : 'd-none'}>
                                                                <td colSpan="13" className="text-center">
                                                                    Please wait… my service will update the socks5 you need
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                        </div>
                                        {
                                            dataLists && dataLists.length > 0 ? (
                                                <div className="text-right">
                                                    <Button type="primary" htmlType="submit" onClick={() => showFill()} className="medium">Fill Sock</Button>
                                                </div>
                                            ) : ''
                                        }
                                        <div className={fillSock ? "fill_txt" : "d-none fill_txt"}>
                                            {
                                                dataLists && dataLists.length > 0 && dataLists.map((item, key) =>
                                                    <span className="d-block">{item.sock_ip}</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default SockApi;