import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { ClockCircleOutlined } from '@ant-design/icons';
import { payment } from '../../endpoint';

const Pendding = () => {
    const history = useHistory();
    const [showConfirmSuccess, setShowConfirmSuccess] = useState(false);

    const startTimer = () => {
        var timer = localStorage.getItem('duration');
        var minutes, seconds;
        if (timer <= 0 || timer == null) {
            setShowConfirmSuccess(true);
        } else {
            var startTimerInterval = setInterval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);
        
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                $("#timeconfirm").html(minutes + ":" + seconds);
                if (--timer < 0) {
                    //timer = duration;
                    clearInterval(startTimerInterval);
                    setShowConfirmSuccess(true);
                }
                if (timer >= 0) {
                    localStorage.setItem('duration', timer);
                }
            }, 1000);
        }
    }

    const checkPayment = () => {
        let confirmId = localStorage.getItem('confirm_id');
        if (confirmId) {
            var checkPaymentInterval = setInterval(function () {
                payment.check_payment({confirm_id: confirmId}).then(res => {
                    if (res.status == 'success' && res.check) {
                        localStorage.removeItem('confirm_id');
                        clearInterval(checkPaymentInterval);
                        window.location.href = "/success.html";
                    }
                }).then(complete => {
                }).then(err => {
                });
            }, 20000);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Pendding - " + window?.title;
        startTimer();
        checkPayment();
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="mt-5 pt-5 pb-5 success-box" style={{maxWidth: "640px", background: "#fff", margin: "0 auto", width: "100%"}}>
                        <ClockCircleOutlined style={{color: "#ff9900", marginBottom: "15px"}} />
                        <p className={showConfirmSuccess ? 'd-none' : ''} style={{fontSize: "32px", fontWeight: "bold", marginBottom: "10px"}} id="timeconfirm"></p>
                        <p className={showConfirmSuccess ? 'd-none' : ''} style={{fontSize: "16px"}}>Your account will be activated within 15 minutes</p>
                        <p className={showConfirmSuccess ? '' : 'd-none'} style={{fontSize: "16px"}}>Confirm Successfully.<br/>Your account will be activate within 6 - 12 hours</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pendding;