import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, Link  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, AutoComplete } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { user_history_credit, users, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const { Option } = AutoComplete;

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await user_history_credit.addItem({ ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        console.log(error);
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await user_history_credit.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const UserHistoryCreditComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const actionRef = useRef(null);
    const [value, setValue] = useState("");
    const [userID, setUserID] = useState(0);
    const [options, setOptions] = useState([]);
    const [currentRow, setCurrentRow] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await user_history_credit.getList(parameters);
        return data;
    }

    /**
     * Load list user select
     *
     * @param parameters
     */
    const getUsers = async (parameters) => {
        const data = await users.getList(parameters);
        return data != null ? data : {};
    }

    /**
     * Handle search user by searchText
     *
     * @param searchText
     */
    const onSearch = async (searchText) => {
        const users = await getUsers({"user_name": searchText});
        setOptions(users.data);
    };

    /**
     * Handle select item user
     *
     * @param data
     * @param record
     */
    const onSelect = (data, record) => {
        setUserID(record.key);
        setValue(data);
    };

    /**
     * Handle onchange item user
     *
     * @param userName
     * @param record
     */
    const onChange = (userName, record) => {
        setUserID(record.key);
        setValue(userName);
    };

    /**
     * Columns show table
     */
    const columns = [
        {
            title:'User Name',
            dataIndex: 'user_name',
            hideInSearch: true
        },
        {
            title:'User Email',
            dataIndex: 'user_mail'
        },
        {
            title:'Price',
            dataIndex: 'credit',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'credit' + record.id}>{record.credit} Point</span>
            ]
        },
        {
            title:'Method',
            dataIndex: 'type',
            valueEnum: {
                '+': {
                    text:'Plus',
                    status: 'Default',
                },
                "-": {
                    text: "Min",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <span key={'type' + record.id}>{record.type == '+' ? "Plus" : "Min"}</span>
            ]
        },
        {
            title:'Type',
            dataIndex: 'object_type',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'object_type' + record.id}>{record?.object_type ? record?.object_type.replace("_", " ") : ''}</span>
            ]
        },
        {
            title:'Final balance',
            dataIndex: 'balance',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'balance' + record.id}>{record.balance} Point</span>
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            valueType: 'date',
            render: (_, record) => [
                <span key={'date' + record.id}>{record.date}</span>
            ]
        },
        // {
        //     title: "Action",
        //     dataIndex: 'option',
        //     valueType: 'option',
        //     hideInSearch: true,
        //     render: (_, record) => [
        //         <a className='d-none'
        //             onClick={() => {
        //                 form.resetFields();
        //                 setCurrentRow(record);
        //                 handleModalVisible(true);
        //             }}
        //         ><EditOutlined /> Edit</a>
        //     ]
        // }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User History Credit Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                            setUserID(null);
                            setCurrentRow({});
                            form.resetFields();
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-history_credit"
                title="History Credit Form"
                width="540px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                }}
            >
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Number Point is required"
                        },
                    ]}
                    initialValue={currentRow?.credit}
                    label="Number Point"
                    placeholder="Number Point"
                    name="credit"
                    width="100%"
                    min={1}
                />

                <div className="ant-form-item-label">
                    <label>User</label>
                </div>
                <AutoComplete
                    style={{ width: "calc(100%)", "maxWidth": '100%', "marginBottom": 15}}
                    onSelect={onSelect} 
                    onSearch={onSearch}
                    onChange={onChange}
                >
                    {options && options.map((record) => (
                        <Option key={record.user_id} value={record.user_name} label={record.user_name}>
                            {record.user_name}
                        </Option>
                    ))}
                </AutoComplete>

                <ProFormSelect
                    name="type"
                    label="Type"
                    width="100%"
                    rules={[
                        {
                            required: true,
                            message: "Type is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Plus', value: '+'},
                        { label: 'Min', value: '-'}
                    ]}
                    initialValue={currentRow?.type}
                    placeholder="Type"
                />

                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        loading={loadingButton}
                        onClick={async () => {
                            const value = form.getFieldsValue();
                            form.submit();
                            if (value.credit && value.type) {
                                if (userID == null) {
                                    notification.open({
                                        message: 'Fail!',
                                        description: "Please Choose user",
                                        type: 'error'
                                    });
                                    return false;
                                }
                                setLoadingButton(true);
                                if (currentRow?.id) {
                                    const success = await handleUpdate({...value}, currentRow?.id);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                } else {
                                    const success = await handleAdd({...value, 'user_id': userID});
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                }
                                setLoadingButton(false);
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserHistoryCreditComponent;
