import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { tracking_sock, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const TrackingSockComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());
        const data = await tracking_sock.getList(parameters);
        return data;
    }

    const columns = [
        {
            title: "User Name",
            dataIndex: 'user_name',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.user_name,
        },
        {
            title: "Plan Name",
            dataIndex: 'plan_name',
            hideInSearch: true,
        },
        {
            title: "Expired New",
            dataIndex: 'user_exdate',
            hideInSearch: true
        },
        {
            title: "Use Today",
            dataIndex: 'count',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.user_name}>{record.count + record.count_api + record.count_tool}</span>
            ]
        },
        {
            title: "Web",
            dataIndex: 'count',
            hideInSearch: true
        },
        {
            title: "Tool",
            dataIndex: 'count_tool',
            hideInSearch: true
        },
        {
            title: "Api",
            dataIndex: 'count_api',
            hideInSearch: true
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User Reset"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 200)
                }}
                rowClassName={(record, index) => (
                    (
                        (
                            record.count + record.count_api + record.count_tool > record?.plan_daily_limit) ? 
                            ('not_is_ready' + (record?.is_user_exdate ? '' : 'not_user_exdate')) : 
                            ('' + (record?.is_user_exdate ? '' : 'not_user_exdate'))
                        )
                    )
                }
            />

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default TrackingSockComponent;
