import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormDateTimePicker, ProFormDigit } from '@ant-design/pro-form';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ProDescriptions from '@ant-design/pro-descriptions';
import { plan, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';
import PlanUpdate from './planUpdate';

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.addItem({ ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        }
        else{
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param plan_id
 */
const handleUpdate = async (fields, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.updateItem({ ...fields, id: plan_id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 *  Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await plan.deleteItems({
            lists: selectedRows.map((row) => row.plan_id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeStatus(record.plan_id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Show
 *
 * @param show
 * @param record
 */
const handledChangeShow = async (show, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeShow(record.plan_id, {'is_show': show ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change trial
 *
 * @param show
 * @param record
 */
const handledChangeTrial = async (show, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeTrial(record.plan_id, {'is_show': show ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Sort
 *
 * @param data
 * @param plan_id
 */
const handledUpdateSort = async (data, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeSort(plan_id, {'sort': data.sort});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Reset
 *
 * @param data
 * @param plan_id
 */
const handledUpdateReset = async (data, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeReset(plan_id, {'is_reset': data ? '1' : '0', 'type': ''});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Reset Free
 *
 * @param data
 * @param plan_id
 */
const handledUpdateResetFree = async (data, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeReset(plan_id, {'is_reset': data ? '1' : '0', 'type': 'is_free'});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change number confirm
 *
 * @param data
 * @param plan_id
 */
const handledUpdateNumberConfirm = async (data, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeNumberConfirm(plan_id, {'number_confirm': data.number_confirm});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change auto confirm time
 *
 * @param val
 * @param plan_id
 */
const changeAutoConfirmTime = async (val, plan_id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await plan.changeAutoConfirmTime(plan_id, {'is_show': val ? 1 : 0});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Popover Item
 */
const PopoverItem = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [value, setValue] = useState(props.text);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <div>
            <span className="popover-edit" id={`Popover-${props.id}`} onClick={toggle.bind(null)}>{value} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverOpen} target={`Popover-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Order
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ sort: value }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateSort(data, props.id)){
                                    setPopoverOpen(false);
                                    setValue(data.sort);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Sort is required"
                                },
                            ]}
                            label="Sort" 
                            name="sort" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

/**
 * Popover Item number confirm
 */
const PopoverNumberConfirmItem = (props) => {
    const [popoverNumberConfirmOpen, setPopoverNumberConfirmOpen] = useState(false);
    const [valueNumberConfirm, setValueNumberConfirm] = useState(props.text);
    const toggle = () => setPopoverNumberConfirmOpen(!popoverNumberConfirmOpen);

    return (
        <div>
            <span className="popover-edit" id={`Popover-Number-ConfirmOpen-${props.id}`} onClick={toggle.bind(null)}>{valueNumberConfirm} <EditOutlined /></span>
            <Popover placement="top" isOpen={popoverNumberConfirmOpen} target={`Popover-Number-ConfirmOpen-${props.id}`} toggle={toggle.bind(null)}>
                <PopoverHeader>
                    Update Number Confirm
                    <span className="close" onClick={toggle.bind(null)}><CloseOutlined size="sm" /></span>
                </PopoverHeader>
                <PopoverBody>
                    <Form
                        name="basic"
                        initialValues={{ number_confirm: valueNumberConfirm }}
                        className="form-horizontal mt-3"
                        onFinish={
                            (data) => {
                                if(handledUpdateNumberConfirm(data, props.id)){
                                    setPopoverNumberConfirmOpen(false);
                                    setValueNumberConfirm(data.number_confirm);
                                }
                            }
                        }
                    >
                        <ProFormDigit 
                            rules={[
                                {
                                    required: true,
                                    message: "Number confirm is required"
                                },
                            ]}
                            label="Number confirm" 
                            name="number_confirm" 
                            width="md" 
                            min={0}
                        />
                        <div className="text-right">
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </div>
    );
}

const PlanComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [updateModalVisible, handleUpdateModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());

        const data = await plan.getList(parameters);
        return data;
    }

    const columns = [
        {
            title: "Name",
            dataIndex: 'plan_name',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.plan_name,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            }
        },
        {
            title:'Plan Type',
            dataIndex: 'plan_type',
            initialValue: paramQuery?.plan_type,
            valueEnum: {
                "Daily": {
                    text:'Daily',
                },
                "API": {
                    text: "API"
                },
                "Credit": {
                    text: "Credit"
                },
                "List": {
                    text: "List"
                },
                "Premium": {
                    text: "Premium"
                }
            },
        },
        {
            title:'Daily Limit	',
            dataIndex: 'plan_daily_limit',
            hideInSearch: true,
        },
        {
            title:'Price',
            dataIndex: 'plan_price',
            hideInSearch: true,
            render: (_, record) => [
                <span key="plan_price">${ record.plan_price }</span>
            ]
        },
        {
            title: "Expired",
            dataIndex: 'plan_expired',
            hideInSearch: true,
            render: (_, record) => [
                <span key="plan_expired">{ record.plan_expired == 0 ? 'Unlimited' : record.plan_expired + ' Days' }</span>
            ]
        },
        {
            title:'Total Socks5 Receive',
            dataIndex: 'total_receive',
            hideInSearch: true,
            render: (_, record) => [
                <span key="total_receive">{ record?.total_receive }</span>
            ]
        },
        {
            title: "Order",
            dataIndex: 'sort',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverItem key="sort" id={record.plan_id} text={record.sort} />
            ]
        },
        {
            title: "Number Confirm",
            dataIndex: 'number_confirm',
            hideInSearch: true,
            render: (_, record) => [
                <PopoverNumberConfirmItem key="number_confirm" id={record.plan_id} text={record.number_confirm} />
            ]
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueType: 'select',
            initialValue: paramQuery?.status,
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatus(value, record)} key={record.plan_id} defaultChecked={record.status  == 1 ? true : false} />
            ]
        },
        {
            title: "Show",
            dataIndex: 'is_show',
            valueType: 'select',
            initialValue: paramQuery?.is_show,
            valueEnum: {
                0: {
                    text:'Not Show',
                    status: 'Default',
                },
                1: {
                    text: "Show",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => handledChangeShow(value, record)} key={record.plan_id} defaultChecked={record.is_show  == 1 ? true : false} />
            ]
        },
        {
            title: "Trial",
            dataIndex: 'is_trial',
            hideInSearch: true,
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => handledChangeTrial(value, record)} key={record.plan_id} defaultChecked={record.is_trial == 1 ? true : false} />
            ]
        },
        {
            title: "Reset",
            dataIndex: 'is_reset',
            hideInSearch: true,
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => handledUpdateReset(value, record.plan_id)} key={record.plan_id} defaultChecked={record.is_reset  == 1 ? true : false} />
            ]
        },
        {
            title: "Reset Free",
            dataIndex: 'is_reset_free',
            hideInSearch: true,
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => handledUpdateResetFree(value, record.plan_id)} key={record.plan_id} defaultChecked={record.is_reset_free  == 1 ? true : false} />
            ]
        },
        {
            title: "Auto Confirm",
            dataIndex: 'is_auto_time',
            hideInSearch: true,
            render: (_, record) => [
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => changeAutoConfirmTime(value, record.plan_id)} key={record.plan_id} defaultChecked={record.is_auto_time  == 1 ? true : false} />
            ]
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a  key="edit"
                    onClick={() => {
                        setCurrentRow(record);
                        handleUpdateModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.plan_id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete"><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Plans Manager"
                actionRef={actionRef}
                request={list}
                rowKey="plan_id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 10)
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-add-plan"
                title="Add new Plan"
                width="500px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    const success = await handleAdd(value);
                    if (success) {
                        form.resetFields();
                        handleModalVisible(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="lg"
                    name="plan_name"
                    label="Name:"
                    placeholder="Name"
                />
                <ProFormSelect
                    width="lg"
                    name="plan_type"
                    label="Plan Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Daily', value: 'Daily'},
                        { label: 'API', value: 'API' },
                        { label: 'Credit', value: 'Credit' },
                        { label: 'List', value: 'List' },
                        { label: 'Premium', value: 'Premium' }
                    ]}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your type!' 
                        }
                    ]}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Daily Limit is required"
                        },
                    ]}
                    label="Daily Limit" 
                    placeholder="Daily Limit" 
                    name="plan_daily_limit" 
                    width="lg" 
                    min={0}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Price is required"
                        },
                    ]}
                    label="Price($)" 
                    placeholder="Price($)" 
                    name="plan_price" 
                    width="lg" 
                    min={0}
                    step="0.01"
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Expired(days) is required"
                        },
                    ]}
                    label="Expired(days)" 
                    placeholder="Expired(days)" 
                    name="plan_expired" 
                    width="lg" 
                    min={0}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Total Socks5 Receive is required"
                        },
                    ]}
                    label="Total Socks5 Receive" 
                    placeholder="Total Socks5 Receive"
                    name="total_receive" 
                    width="lg" 
                    min={0}
                />
                <ProFormSelect
                    width="lg"
                    name="status"
                    label="Status"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Active', value: '1'},
                        { label: 'Deactive', value: '0' }
                    ]}
                    placeholder="Please select a status"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your status!' 
                        }
                    ]}
                />
            </ModalForm>

            {/*Form Update*/}
            <PlanUpdate
                onSubmit={async (value) => {
                    const success = await handleUpdate(value, currentRow?.plan_id);
                    if (success) {
                        handleUpdateModalVisible(false);
                        setCurrentRow(undefined);

                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
                onCancel={() => {
                    handleUpdateModalVisible(false);
                    setCurrentRow(undefined);
                }}
                updateModalVisible={updateModalVisible}
                values={currentRow || {}}
            />
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.plan_id && (
                    <ProDescriptions 
                        title={currentRow?.user_name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.plan_id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default PlanComponent;
