import React, { useState, useRef, useEffect } from 'react';
import { useHistory} from "react-router-dom";
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import ProDescriptions from '@ant-design/pro-descriptions';
import { user_credit, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const UserCreditComponent = () => {
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};
        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());
        const data = await user_credit.getList(parameters);
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "User Name",
            dataIndex: 'user_name',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.user_name,
        },
        {
            title: "Email",
            dataIndex: 'user_mail',
            valueType: 'text',
            initialValue: paramQuery?.user_mail,
        },
        {
            title: "Point Current",
            dataIndex: 'credit',
            hideInSearch: true
        },
        {
            title: "Point loaded",
            dataIndex: 'credit_plus',
            hideInSearch: true
        },
        {
            title: "Point using",
            dataIndex: 'credit_min',
            hideInSearch: true
        },
        {
            title:'Date Register',
            dataIndex: 'date',
            hideInSearch: true
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User Tracking Credit"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 20)
                }}
                rowClassName={(record, index) => (
                    (
                        record.credit_plus - record.credit_min < record.credit) ? 'not_is_ready' : ''
                    )
                }
            />

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserCreditComponent;
