import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import ProDescriptions from '@ant-design/pro-descriptions';
import { user_tracking_confirm, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const UserTrackingConfirm = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        const data = await user_tracking_confirm.getList(parameters);
        return data;
    }

    const renderPaymentMethod = (type) => {
        if (type == 'pm' || type == 'perfect-money') {
            return 'Perfect Money'
        } else if (type == 'wmz' || type == 'webMoney') {
            return 'WebMoney';
        } else if (type == 'bitcoin' || type == 'btc') {
            return 'Bitcoin (BTC)';
        } else if (type == 'bitcoincash') {
            return 'Bitcoin Cash (BCH)';
        } else if (type == 'erc20') {
            return 'USDT (ERC20)';
        } else if (type == 'trc20') {
            return 'USDT (TRC20)';
        } else if (type == 'bep20') {
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else {
            return 'Other';
        }
    }

    const columns = [
        {
            title: "User Name",
            dataIndex: 'user_name',
            sorter: false,
            hideInSearch: true,
            initialValue: paramQuery?.user_name,
        },
        {
            title: "Plan Name",
            dataIndex: 'plan_name',
            sorter: false,
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.user_id}>{record.plan != null ? record?.plan : record?.package}</span>
            ]
        },
        {
            title: "Price",
            dataIndex: 'plan_price',
            sorter: false,
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.user_id}>${record.plan != null ? record?.plan_price : record?.package_price}</span>
            ]
        },
        {
            title:'Type',
            dataIndex: 'type',
            valueEnum: {
                "pm": {
                    text:'Perfect Money',
                },
                "wmz": {
                    text: "WebMoney"
                },
                "bitcoin": {
                    text: "Bitcoin (BTC)"
                },
                "bitcoincash": {
                    text: "Bitcoin Cash (BCH)"
                },
                "usdt": {
                    text: "USDT"
                }
            },
            render: (dom, item) => {
                return (
                    <span>{renderPaymentMethod(item.type)}</span>
                );
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User Trial"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    pageSize: (paramQuery?.pageSize || 20)
                }}
                rowClassName={(record, index) => (
                    (
                        record?.order_id) ? 'bg-green-tr' : ''
                    )
                }
            />

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserTrackingConfirm;
