import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, InputNumber, Button, Select, Pagination, Spin, notification, message, Modal } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { sock_list, account, banner, authenticator } from '../../endpoint';
import { getParamsByUrlPaging, copyTextToClipboard } from '../../_until';

const SockLists = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadingAutoGetSock, setLoadingAutoGetSock] = useState(true);
    const [dataCountry, setDataCountry] = useState([]);
    const [dataState, setDataState] = useState([]);
    const [dataCity, setDataCity] = useState([]);
    const [dataLists, setDataLists] = useState([]);
    const [dataForm, setDataForm] = useState({});
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [userToday, setUserToday] = useState(0);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const [numberGetSock, setNumberGetSock] = useState(0);
    const [profile, setProfile] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataAds, setBannerAds] = useState([]);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [isResetFree, setIsResetFree] = useState(0);
    const [isReset, setIsReset] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFreeVisible, setIsModalFreeVisible] = useState(false);
    const [fillSock, setfillSock] = useState(false);
    const [listRichLevel, setListRichLevel] = useState([
        {value: 1, label: 'Low'},
        {value: 2, label: 'Medium'},
        {value: 3, label: 'Hight'},
        {value: 4, label: 'Very High'}
    ]);
    const [listRichLevelAll, setListRichLevelAll] = useState([]);
    const [formatIpPort, setFormatIpPort] = useState(false);
    const [listFormatIpPort, setListFormatIpPort] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    const onFinish = (values) => {
        setCurrent(1);
        setDataForm(values);
        loadList(values);
    };

    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    const handleCancelReset = () => {
        setIsModalVisible(false);
    };

    const handleCancelFree = () => {
        setIsModalFreeVisible(false);
    };

    const showModalReset = () => {
        setIsModalVisible(true);
    }

    const showModalFree = () => {
        setIsModalFreeVisible(true);
    }

    const onResetForm = () => {
        if (profile?.plan_type != "Premium") {
            notification.open({
                message: 'Fail!',
                description: "Please buy plan Socks5 Premium before viewing this item.",
                type: 'error'
            });
            return false;
        }
        form.resetFields();
        setDataForm({});
        setListRichLevel(listRichLevelAll);
        loadList({current: 1, pageSize: 20});
    }

    const showFill = () => {
        setFormatIpPort(false);
        let textFill = '';
        $('.sock').each(function() {
            if ($(this).hasClass('is_success')) {
                textFill = textFill + $(this).html() + '\n';
            }
        });
        $('.section-fillsock textarea').val(textFill);
        setfillSock(true);
    }

    const copyShowFill = () => {
        let textFill = '';
        $('.sock').each(function() {
            if ($(this).hasClass('is_success')) {
                textFill = textFill + $(this).html() + '\n';
            }
        });
        if (copyTextToClipboard(textFill)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    const copySock = (className) => {
        let tdSock = $('.' + className);
        if (tdSock.length > 0 && tdSock.hasClass('is_success')) {
            let sock = tdSock.html();
            if (copyTextToClipboard(sock)) {
                notification.open({
                    message: 'Success',
                    description: "Copy Success!.",
                    type: 'success'
                });
            } else {
                notification.open({
                    message: 'Fail!',
                    description: "Copy fail!.",
                    type: 'error'
                });
            }
        }
    }

    const copyText = (textCopy) => {
        if (copyTextToClipboard(textCopy)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    const fillFormatIpPort = () => {
        setFormatIpPort(!formatIpPort);
        setfillSock(false);
        var listSockFormat = [];
        for (let index = 0; index < dataLists.length; index++) {
            const sockItem = dataLists[index];
            if ($(".sock-" + sockItem.sock_id).hasClass('is_success')) {
                sockItem['sock_ip'] = $(".sock-" + sockItem.sock_id).html();
                listSockFormat.push(sockItem);
            }
        }
        setListFormatIpPort(listSockFormat);
    }

    const resetPlan = () => {
        account.reset_plan({is_free: isModalFreeVisible ? 1 : 0}).then(res => {
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Reset successfully',
                    type: 'success'
                });
                handleCancel();
                handleCancelFree();
                location.reload();
            } else {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).catch(err => {
            notification.open({
                message: 'Fail!',
                description: "Error",
                type: 'error'
            });
        });
    }

    const onChangeNumberSock = (val) => {
        setNumberGetSock(val);
    }

    const autoGetSockHandle = () => {
        if (profile?.plan_type != "Premium") {
            notification.open({
                message: 'Fail!',
                description: "Please buy plan Socks5 Premium before viewing this item.",
                type: 'error'
            });
            return false;
        }

        if (numberGetSock <= 0 || numberGetSock > 20) {
            notification.open({
                message: 'Can’t check socks5 live',
                description: "Please choose quantity equal to or less than 20 socks5.",
                type: 'error'
            });
        } else {
            //loadList({current: 1, pageSize: 20, ...form.getFieldsValue()});
            setLoadingAutoGetSock(true);
            let index = 0;
            let countSock = 0;
            var autoInterval = setInterval(function() {
                if (countSock < numberGetSock && index < dataLists.length) {
                    let sockId = dataLists[index].sock_id;
                    if ($('#view-sock' + sockId).length > 0 && !$('#view-sock' + sockId).hasClass('complete')) {
                        $('#view-sock' + sockId).find('.icon-loading').removeClass('d-none');
                        $('#view-sock' + sockId).find('.sock-text').addClass('d-none');
                        $('#view-sock' + sockId).addClass('complete');
                        sock_list.viewSock({sock_id: sockId}).then(res => {
                            if (res.status == 'success') {
                                $('#view-sock' + sockId).parent().addClass('is_success');
                                $('#view-sock' + sockId).parents('tr').find('.sock-host').html(res.response.sock_hostname);
                                $('#view-sock' + sockId).parent().html(res.response.sock_ip);
                                setUserToday(res.used_today);
                            } else if(res.status == 'fail') {
                                $('#view-sock' + sockId).find('.sock-text').addClass('text-red').html(res.message);
                            }
                        }).then(err => {
                            setTimeout(function() {
                                $('#view-sock' + sockId).find('.icon-loading').addClass('d-none');
                                $('#view-sock' + sockId).find('.sock-text').removeClass('d-none');
                            }, 1000);
                        }).then(complete => {
                        });
                        countSock++;
                    }
                    index++;
                } else {
                    clearInterval(autoInterval);
                    setLoadingAutoGetSock(false);
                }
            }, 2000);
        }
    }

    const viewSock = (sock_id) => {
        if (profile?.plan_type != "Premium") {
            notification.open({
                message: 'Fail!',
                description: "Please buy plan Socks5 Premium before viewing this item.",
                type: 'error'
            });
            return false;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        if (user == null) {
            notification.open({
                message: 'Fail!',
                description: "Please login!.",
                type: 'error'
            });
        } else {
            if (!checkAllow()) {
                $('#view-sock' + sock_id).parent().html("Too fast, please slowly. (Please wait 2 second)");
                $('#view-sock' + sock_id).addClass('complete');
            } else {
                $('#view-sock' + sock_id).find('.icon-loading').removeClass('d-none');
                $('#view-sock' + sock_id).find('.sock-text').addClass('d-none');
                $('#view-sock' + sock_id).addClass('complete');
                sock_list.viewSock({sock_id: sock_id}).then(res => {
                    if (res.status == 'success') {
                        $('#view-sock' + sock_id).parent().addClass('is_success');
                        $('#view-sock' + sock_id).parents('tr').find('.sock-host').html(res.response.sock_hostname);
                        $('#view-sock' + sock_id).parent().html(res.response.sock_ip);
                        setUserToday(res.used_today);
                    } else if (res.status == 'fail') {
                        $('#view-sock' + sock_id).find('.sock-text').addClass('text-red').html(res.message);
                    }
                }).then(err => {
                    setTimeout(function(){
                        $('#view-sock' + sock_id).find('.icon-loading').addClass('d-none');
                        $('#view-sock' + sock_id).find('.sock-text').removeClass('d-none');
                    }, 1000);
                }).then(complete => {

                });
            }
        }
    }

    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);
        setLoadingAutoGetSock(true);
        setfillSock(false);
        setFormatIpPort(false);
        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);
        
        sock_list.getSockPremium(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setUserToday(res.used_today);
                if (values?.current) {
                    setCurrent(parseInt(values?.current));
                }
            }
        }).then(err => {
            hide();
            setLoading(false);
            setLoadingAutoGetSock(false);
        }).then(complete => {
            setLoading(false);
            setLoadingAutoGetSock(false);
        });
    }

    const changeTypeSock = (values) => {
        form.setFieldsValue({'sock_country': 'all'});
        form.setFieldsValue({'sock_state': 'all'});
        form.setFieldsValue({'sock_city': 'all'});
        form.setFieldsValue({'level': ''});
        sock_list.getCountry({all: 1, type: values}).then(res => {
            if (res.status == 'success') {
                setDataCountry(res.response);
                setDataState({});
                setDataCity({});
            }
        });

        sock_list.getRickLevel({all: 1, type: values}).then(res => {
            if (res.status == 'success') {
                setListRichLevel(res.response);
            }
        });
    }

    const changeRichLevel = (rickLevel) => {
        let type = form.getFieldValue('type_sock');
        form.setFieldsValue({'sock_country': 'all'});
        form.setFieldsValue({'sock_state': 'all'});
        form.setFieldsValue({'sock_city': 'all'});
        sock_list.getCountry({all: 1, type: type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataCountry(res.response);
                setDataState({});
                setDataCity({});
            }
        });
    }

    const changeCountry = (values) => {
        let type = form.getFieldValue('type_sock');
        let rickLevel = form.getFieldValue('level');
        sock_list.getState({all: 1, sock_country: values, 'type': type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataState(res.response);
            }
        });
    }

    const changeState = (values) => {
        let type = form.getFieldValue('type_sock');
        let rickLevel = form.getFieldValue('level');
        let country = form.getFieldValue('sock_country');
        form.setFieldsValue({'sock_city': 'all'});
        sock_list.getCity({all: 1, sock_country: country, sock_states: values, 'type': type, 'rick_level': rickLevel}).then(res => {
            if (res.status == 'success') {
                setDataCity(res.response);
            }
        });
    }

    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    const getLevel = (level) => {
        if (level == 1) {
            return "Low";
        } else if (level == 2) {
            return "Medium";
        } else if (level == 3) {
            return "Hight";
        } else if (level == 4) {
            return "Very High";
        }
        return "";
    }

    const checkAllow = () =>  {
        let count = 1;
        if (getWithExpiry('request_count') == null) {
            setWithExpiry('request_count', count, 2000);
        } else {
            count = getWithExpiry('request_count');
        }
        if (count <= 1) {
            setWithExpiry('request_count', count + 1, 2000);
            return true;
        }
        return false;
    }

    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() >= item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    const setWithExpiry = (key, value, ttl) =>  {
        const now = new Date()
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    useEffect(() => {
        document.title = "Socks5 Premium - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                            setIsResetFree(res.response.is_reset_free);
                            setIsReset(res.response.is_reset);
                        }
                    }).then(err => {
                        setLoadingProfile(false);
                    }).then(complete => {
                        setLoadingProfile(false);
                    });
                }
            }).then(res => {
            });

            sock_list.getCountry({all: 1, premium: 1}).then(res => {
                if (res.status == 'success') {
                    setDataCountry(res.response);
                }
            });

            loadList({all: 1, ...paramQuery});
            if(paramQuery?.current){
                delete paramQuery['current'];
                delete paramQuery['pageSize'];
            }
            setDataForm(paramQuery);

            banner.get_list_banner({position: 'sock'}).then(res => {
                if (res.status == 'success') {
                    setListBanner(res.response);
                }
            });
        }
        setListRichLevelAll(listRichLevel);
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row align-items-center">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content search-socks-list">
                            {
                                loadingProfile ? (
                                    <div className="text-center mt-5 mb-5"><Spin /></div>
                                ) :
                                (
                                    profile?.plan_type != "Premium" ? (
                                        <div className="alert alert-danger text-center" style={{maxWidth: "800px", margin: "15px auto"}}>Please buy plan Socks5 Premium before viewing this item</div>
                                    ) : 
                                    (
                                        <div>
                                            <div className="form-search">
                                                <div className="padding-10">
                                                    <Form
                                                        name="basic"
                                                        form={form}
                                                        className="form-horizontal mt-3"
                                                        onFinish={onFinish}
                                                        initialValues={paramQuery}
                                                    >
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5><b>Type Socks</b></h5>
                                                                            <Form.Item name="type_sock">
                                                                                <Select
                                                                                    style={{width: "100%"}}
                                                                                    onChange={changeTypeSock}
                                                                                    placeholder="All"
                                                                                >
                                                                                    <Option value="">All</Option>
                                                                                    <Option value="business">Business</Option>
                                                                                    <Option value="cellular">Cellular</Option>
                                                                                    <Option value="hosting">Hosting</Option>
                                                                                    <Option value="residential">Residential</Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5><b>Risk Level</b></h5>
                                                                            <Form.Item name="level">
                                                                                <Select
                                                                                    style={{width: "100%"}}
                                                                                    onChange={changeRichLevel}
                                                                                    placeholder="All"
                                                                                >
                                                                                    <Option value="">All</Option>
                                                                                    {
                                                                                        listRichLevel.length > 0 && listRichLevel.map((item, key) => 
                                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5><b>Search Sock</b></h5>
                                                                            <Form.Item name="sock_country">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{width: "100%"}}
                                                                                    placeholder="All Country"
                                                                                    onChange={changeCountry}
                                                                                    filterOption={(input, option) =>
                                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase()) ||
                                                                                        (input.length == 2 && option.props.value.toLowerCase() == input.toLowerCase())
                                                                                    }
                                                                                >
                                                                                    <Option value="all">All Country</Option>
                                                                                    {
                                                                                        dataCountry.length  > 0 && dataCountry.map((item, key) => 
                                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                                            <Form.Item name="sock_state">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{width: "100%"}}
                                                                                    placeholder="All State"
                                                                                    onChange={changeState}
                                                                                    filterOption={(input, option) =>
                                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                                    }
                                                                                >
                                                                                    <Option value="all">All State</Option>
                                                                                    {
                                                                                        dataState.length  > 0 && dataState.map((item, key) => 
                                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                                            <Form.Item name="sock_city">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{width: "100%"}}
                                                                                    placeholder="All City"
                                                                                    filterOption={(input, option) =>
                                                                                        (input.length == 1 && option.props.children.substr(0, 1).toLowerCase() == input.toLowerCase())
                                                                                    }
                                                                                >
                                                                                    <Option value="all">All City</Option>
                                                                                    {
                                                                                        dataCity.length  > 0 && dataCity.map((item, key) => 
                                                                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <div className="form-group">
                                                                            <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                                            <Form.Item name="black_list">
                                                                                <Select
                                                                                    style={{width: "100%"}}
                                                                                    placeholder="Black List"
                                                                                >
                                                                                    <Option value="">All</Option>
                                                                                    <Option value="0">Clear</Option>
                                                                                    <Option value="1">BlackList</Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-2">
                                                                <h5><b>Search Zipcode</b></h5>
                                                                <div className="row">
                                                                    <div className="form-group col-sm-12">
                                                                        <Form.Item name="sock_zipcode">
                                                                            <Input style={{width: "100%"}} name="txtZip" placeholder="Search Zipcode"/>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h5><b>ISP Name</b></h5>
                                                                <div className="row">
                                                                    <div className="form-group col-sm-12">
                                                                        <div className="form-group">
                                                                            <Form.Item name="sock_hostname">
                                                                                <Input style={{width: "100%"}} name="txtHostName" placeholder="Search ISP Name"/>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <h5 className='d-none d-sm-block'><b>&nbsp;</b></h5>
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className="col-auto">
                                                                            <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Search</Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="col-auto">
                                                                            <Button type="primary" htmlType="button" onClick={onResetForm} className="d-block medium">Clear</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <h5><b>Auto get socks5:</b></h5>
                                                                <div className="row">
                                                                    <div className="col-sm-8">
                                                                        <div className="form-group">
                                                                            <InputNumber style={{width: "100%"}} onChange={onChangeNumberSock} min={1} placeholder="Number Socks"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="form-group">
                                                                            <Button type="primary" onClick={autoGetSockHandle} loading={loadingAutoGetSock} className="d-block medium">Get Socks5</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            <div className="plan-table">
                                                <p className='text-center mb-0'><b>Plan:</b> <b className='text-success'>{profile?.plan_current_name}</b></p>
                                                <p className='text-center mb-3'><b>Expired:</b> <b className='text-success'>{profile?.user_exdate} ({profile?.user_exdate_left})</b></p>
                                                <h4 className="text-center mb-3">Used today: {userToday}</h4>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-socklist table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Ip</th>
                                                                            <th>Country</th>
                                                                            <th>Region</th>
                                                                            <th>City</th>
                                                                            <th>Zipcode</th>
                                                                            <th>ISP Name</th>
                                                                            <th>Type Sock</th>
                                                                            <th>Risk Score</th>
                                                                            <th>Risk Level</th>
                                                                            <th>Spam Mail</th>
                                                                            <th>VPN</th>
                                                                            <th>Online</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dataLists.length  > 0 && dataLists.map((item, key) => 
                                                                                <tr key={item.sock_id}>
                                                                                    <td data-title="Sock5" className={"sock " + ("sock-" + item.sock_id)} onClick={() => copySock("sock-" + item.sock_id)}>
                                                                                        <a id={"view-sock" + item.sock_id} onClick={() => viewSock(item.sock_id)}>
                                                                                            <span className="sock-text">{item.sock_ip}</span>
                                                                                            <span className="icon-loading d-none"><Spin size="small" /></span>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td data-title="Country">
                                                                                        <img src={item.country_img} />&nbsp;&nbsp;
                                                                                        {item.sock_country}
                                                                                    </td>
                                                                                    <td data-title="Region">{item.sock_states}</td>
                                                                                    <td data-title="City">{item.sock_city}</td>
                                                                                    <td data-title="Zipcode">{item.sock_zip}</td>
                                                                                    <td data-title="ISP Name" className="host"><span className="sock-host">{item.sock_hostname}</span></td>
                                                                                    <td data-title="Type Sock" className="text-capitalize">{item.type_sock}</td>
                                                                                    <td data-title="Risk Score">{item.score}/100</td>
                                                                                    <td data-title="Risk Level">{getLevel(item.level)}</td>
                                                                                    <td data-title="Spam Mail">{item.sock_back_list == '1' ? 'BlackList' : 'No'}</td>
                                                                                    <td data-title="VPN">{item.vpn == '1' ? 'Yes' : 'No'}</td>
                                                                                    <td data-title="Online">{item.sock_times}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr className={dataLists.length == 0 ? '' : 'd-none'}>
                                                                            <td colSpan="12" className="text-center no-padding">
                                                                                Please wait website update new socks5.
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        }
                                                        {
                                                            total > 10 && !loading ? (
                                                                <div className="paging mt-4">
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-center">
                                                                            <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize="20" onChange={onChange} />    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                dataLists && dataLists.length > 0 ? (
                                                    <div className="text-right mt-3">
                                                        <Button type="primary" htmlType="button" onClick={() => copyShowFill()} className="medium mr-3">Copy List Socks5</Button>
                                                        <Button type="primary" htmlType="button" onClick={() => showFill()} className="medium mr-3">Fill Sock</Button>
                                                        <Button type="primary" htmlType="button" onClick={() => fillFormatIpPort()} className="medium">Format IP:PORT</Button>
                                                    </div>
                                                ) : ''
                                            }
                                            <div className='mt-3 section-fillsock'>
                                                <a onClick={() => copyShowFill()} className={fillSock ? "copy-fillsock" : "d-none copy-fillsock"} ><i className="fa fa-copy" aria-hidden="true"></i></a>
                                                <textarea className={fillSock ? "form-control" : "d-none form-control"} rows={10}></textarea>
                                            </div>
                                            {
                                                formatIpPort ? (
                                                    <div className='mt-3 section-fillsock'>
                                                        <h5><b>Format IP:PORT</b></h5>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped table-sock-list table-list-mobile">
                                                                <thead>
                                                                    <tr>
                                                                        <th>IP</th>
                                                                        <th>Port</th>
                                                                        <th>Country</th>
                                                                        <th>Region</th>
                                                                        <th>City</th>
                                                                        <th>Zipcode</th>
                                                                        <th>ISP Name</th>
                                                                        <th>Type Sock</th>
                                                                        <th>Risk Score</th>
                                                                        <th>Risk Level</th>
                                                                        <th>Spam Mail</th>
                                                                        <th>VPN</th>
                                                                        <th>Online</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        listFormatIpPort.length  > 0 && listFormatIpPort.map((item, key) => 
                                                                            <tr key={item.sock_id}>
                                                                                <td data-title="IP">
                                                                                    <a onClick={() => copyText(item.sock_ip.split(":")[0])}>{item.sock_ip.split(":")[0]}</a>
                                                                                    </td>
                                                                                <td data-title="Port">
                                                                                    <a onClick={() => copyText(item.sock_ip.split(":")[1])}>{item.sock_ip.split(":")[1]}</a>
                                                                                </td>
                                                                                <td data-title="Country">
                                                                                    <img src={item.country_img} />&nbsp;&nbsp;
                                                                                    {item.sock_country}
                                                                                </td>
                                                                                <td data-title="Region">{item.sock_states}</td>
                                                                                <td data-title="City">{item.sock_city}</td>
                                                                                <td data-title="Zipcode">{item.sock_zip}</td>
                                                                                <td data-title="ISP Name" className="host">{item.sock_hostname}</td>
                                                                                <td data-title="Type Sock" className="text-capitalize">{item.type_sock}</td>
                                                                                <td data-title="Risk Score">{item.score}/100</td>
                                                                                <td data-title="Risk Level">{getLevel(item.level)}</td>
                                                                                <td data-title="Spam Mail">{item.sock_back_list == '1' ? 'BlackList' : 'No'}</td>
                                                                                <td data-title="VPN">{item.vpn == '1' ? 'Yes' : 'No'}</td>
                                                                                <td data-title="Online">{item.sock_times}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                    )
                                )
                             }
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
            <Modal title="Reset Account" visible={isModalVisible} onCancel={handleCancelReset} footer={null} width={580}>
                <p className="text-center mb-3"><b>If you reset your account today<br/>Your account will be reduced by 1 day of use</b></p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancelReset()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>

            <Modal title="Reset Free Account" visible={isModalFreeVisible} onCancel={handleCancelFree} footer={null} width={580}>
                <p className="text-center mb-3"><b>Your account has 1 free reset every month<br/>You want reset your account today?</b></p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancelFree()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>
        </div>
    );
}
export default SockLists;