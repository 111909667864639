import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Modal, notification, message, Spin } from 'antd';
import { UserOutlined, LockOutlined, UndoOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import ReCAPTCHA from "react-google-recaptcha";
import { googleKey } from '../../../_until';
import { account,banner } from '../../../endpoint';

const SidebarRight = () => {

    const history  = useHistory();
    const [googleVaue, setGoogleVaue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [profile, setProfile] = useState([]);
    const [dataBanner, setBanner] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFreeVisible, setIsModalFreeVisible] = useState(false);
    const [isResetFree, setIsResetFree] = useState(0);
    const user = JSON.parse(localStorage.getItem('user'));

    const onFinish = async (values) => {
        if (googleVaue != null) {
            const hide = message.loading('Processing...');
            try {
                setLoading(true);
                var requestOptions = {...values, 'gg_code': googleVaue};
                var res = await account.login(requestOptions);
                hide();
                if (res.status == 'success') {
                    const user = res.response;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                    notification.open({
                        message: 'Success',
                        description: 'Login successfully',
                        type: 'success'
                    });
                    history.go({pathname: '/home'});
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
            } catch (error) {
                hide();
                notification.open({
                    message: 'Fail!',
                    description: 'Login failed, please try again!',
                    type: 'error'
                });
            }
            setLoading(false);
        } else {
            notification.open({
                message: 'Fail!',
                description: "Please enter Captcha",
                type: 'error'
            });
        }
    };
    
    const onChange = (value) => {
        setGoogleVaue(value);
    }
    
    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCancelFree = () => {
        setIsModalFreeVisible(false);
    };

    const showModal = () => {
        setIsResetFree(0);
        setIsModalVisible(true);
    }

    const showModalFree = () => {
        setIsResetFree(1);
        setIsModalFreeVisible(true);
    }

    const resetPlan = () => {
        account.reset_plan({is_free: isResetFree}).then(res => {
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Reset successfully',
                    type: 'success'
                });
                handleCancel();
                handleCancelFree();

                setLoadingProfile(true);
                account.profile().then(data => {
                    if (data.status == 'success') {
                        setProfile(data.response);
                        setLoadingProfile(false);
                    }
                });
            } else {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).catch(err =>{
            notification.open({
                message: 'Fail!',
                description: "Error",
                type: 'error'
            });
        });
    }

    useEffect(() => {
        if (user != null) {
            account.profile().then(res => {
                if (res.status == 'success') {
                    setProfile(res.response);
                    setLoadingProfile(false);
                }
            });
        }

        banner.get_list_banner({position: 'banner_sidebar'}).then(res => {
            if (res.status == 'success') {
                setBanner(res.response);
            }
        })
        .then(err => {
            setLoadingProfile(false);
        })
        .then(err => {
            setLoadingProfile(false);
        });
    }, []);

    return (
        <div className="sidebar-right">
            <div className="panel-heading text-center">
                <h6 className="remove-margin text-white">{user != null ? "My Account" : "Login"}</h6>
            </div>
            <div className="panel-body">
                {user != null ? 
                    (
                        loadingProfile ? 
                        (<div className="text-center mt-5 mb-5"><Spin /></div>) : 
                        (
                            <div className="info-login">
                                <ul className="list-unstyled">
                                    <li>Email: <span>{profile?.user_mail}</span></li>
                                    <li>User: <span>{profile?.user_name}</span></li>
                                    <li>Plan: <span>{profile?.plan_name}</span></li>
                                    <li>Expired: <span>{profile?.user_exdate}</span></li>
                                    <li>Used today: <span>{profile?.count}</span></li>
                                    <li className="d-none">Last Login: <span>{profile?.user_lastlogin}</span></li>
                                    {
                                        profile?.plan_name == 'Socks5 All' ? (
                                            <li>Limit: <span>Unlimited socks5 per day</span></li>
                                        ) : (
                                            <li>Limit: <span>{profile?.count} of {profile?.limit} socks</span></li>
                                        )
                                    }
                                    <li>
                                        <Link to="/2fa.html" className="d-block medium btn btn-primary">
                                            2FA Authentication <AppstoreAddOutlined className="site-form-item-icon" />
                                        </Link>
                                    </li>
                                    <li className="d-none">Bonus: <span>{profile?.bonus}$</span></li>
                                    {
                                        profile?.is_reset == 1 && (profile?.plan_type == 'Daily' || profile?.plan_type == 'List') ? (
                                            <li className="d-none">
                                                <Button type="primary" onClick={() => showModal()} htmlType="submit" className="d-block medium text-white">
                                                    Reset <UndoOutlined className="site-form-item-icon" />
                                                </Button>
                                            </li>
                                        ) : ''
                                    }
                                    {
                                        profile?.is_reset == 1 && profile?.is_reset_free == 1 && (profile?.plan_type == 'Daily' || profile?.plan_type == 'List') ? (
                                            <li className="d-none">
                                                <Button type="primary" onClick={() => showModalFree()} htmlType="submit" className="d-block medium text-white">
                                                    Reset Free <UndoOutlined className="site-form-item-icon" />
                                                </Button>
                                            </li>
                                        ) : ''
                                    }
                                </ul>
                            </div>
                        )
                    ) :
                    (
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="form-horizontal mt-3 pd-2"
                            id="loginform"
                        >
                            <Form.Item
                                label="Username or Email:"
                                labelCol={{span: 24}}
                                name="username"
                                rules={[{ required: true, message: 'Please input your Username or Email!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name or Email!" />
                            </Form.Item>
                            <Form.Item
                                label="Password:"
                                labelCol={{span: 24}}
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                            </Form.Item>
                            <div className="mb-3 gg-captcha">
                                <ReCAPTCHA
                                    sitekey={googleKey()}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-2 text-center">
                                <div className="col-xs-12">
                                    <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Log In</Button>
                                </div>
                            </div>
                            <div className="mb-3 text-center">
                                <a className="btn-continue-google btn-block m-0" href="/signup-google">Google</a>
                            </div>
                            <div className="mb-3 text-center">
                                <a className="btn-continue-microsoft btn-block m-0" href="/signup-microsoft">Microsoft</a>
                            </div>
                            <hr />
                            <p className="text-center">
                                <span>
                                    <Link to="/register.html">Register</Link>
                                </span>
                                <span> | </span> <br />
                                <span>
                                    <Link to="/forgot.html">Forgot password</Link>
                                </span>
                            </p>
                        </Form>
                    ) 
                }
            </div>


            <div className="panel-body">
                <div className="mt-4">&nbsp;</div>
                {
                    loadingProfile ? 
                    (<div className="text-center mt-5 mb-5"><Spin /></div>) :
                    (
                        dataBanner && dataBanner.length > 0 && dataBanner.map((item, key) => 
                            <div className="item" key={item.id}>
                                <a href={item.url} target="_blank">
                                    <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                                </a>
                            </div>
                        )
                    )
                }
            </div>
            <Modal title="Reset Account" visible={isModalVisible} onCancel={handleCancel} footer={null} width={580}>
                <p className="text-center mb-3"><b>If you reset your account today<br/>Your account will be reduced by 1 day of use</b></p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancel()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>

            <Modal title="Reset Free Account" visible={isModalFreeVisible} onCancel={handleCancelFree} footer={null} width={580}>
                <p className="text-center mb-3"><b>Your account has 1 free reset every month<br/>You want reset your account today?</b></p>
                <p className="text-center">
                    <Button type="primary" onClick={() => resetPlan()} htmlType="submit" className="medium text-white mr-3">
                        Agree
                    </Button>
                    <Button type="danger" onClick={() => handleCancelFree()} htmlType="submit" className="medium">
                        Cancel
                    </Button>
                </p>
                <p className="text-center mt-3">
                    <span className="text-danger"><b>Support</b></span><br/> 
                    Email: {window.confirm?.support_email}<br/> 
                    Skype: {window.confirm?.skype}
                </p>
            </Modal>
        </div>
    );
}

export default SidebarRight;
